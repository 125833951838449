<template>
  <div class="blCertificate capture_area">
    <div class="content_box beforetab">
      <!-- content_box -->
      <table class="tbl_search">
        <colgroup>
          <col width="70px"><col><col width="100px"><col><col width="70px"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.ONEX050T010.001') }}</th> <!-- B/L No. -->
            <td>
              {{ blNo }}
            </td>
            <th>{{ $t('msg.ONEX050T010.002') }}</th> <!-- Booking No. -->
            <td>
              {{ params.bkgNo }}
            </td>
            <!-- <th>{{ $t('msg.ONEX050T010.003') }}</th>--> <!-- Ref No. -->
            <!-- <td>
              <template v-for="(certificate, index) in certificateList">
                <a
                  href="#"
                  :id="certificate.crtfNo"
                  class="certiNo"
                  @click.prevent="modifyCertificate(certificate.crtfNo)"
                  :key="index"
                  :style="{ 'font-weight': certificate.crtfNo === params.crtfNo ? 'bold' : '' }"
                >
                  {{ certificate.crtfNo }}({{ certificate.apvStsNm }}) <span v-if="index != certificateList.length - 1">|</span>
                </a>
              </template>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->

    <div class="content_box mt10">
      <!-- content_box -->
      <table class="tbl_search">
        <colgroup>
          <col v-if="lang === 'JPN'" width="160px">
          <col v-else width="120px">
          <col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.CSBK100.393') }}</th> <!-- 불러오기 -->
            <td>
              <input type="text" id="callPop" name="callPop" class="wid200" v-model="inputCrtfNo" @keypress="templateEnter">
              <button class="tbl_icon search" @click.prevent="fnCallPop">{{ $t('msg.ONEX050T010.005') }}</button> <!-- 검색 -->
              <a class="button blue sh ml15" href="#" @click.prevent="fnApplyCertiTemplate">{{ $t('msg.ONEX050T010.006') }}</a> <!-- 적용 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->

    <div class="content_box mt10">
      <table class="tbl_col">
        <colgroup>
          <col style="width:10%">
          <col style="width:10%">
          <col style="width:20%">
          <col style="width:20%">
          <col style="width:20%">
          <col style="width:10%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.CSBL100_P1.016') }}<!-- Reference No. --></th>
            <th>{{ $t('msg.ONGO080G010.011') }}<!-- Status --></th>
            <th>{{ $t('msg.CMBA380.005') }}<!-- Request Date --></th>
            <th>{{ $t('msg.CMBA380.042') }}<!-- Corrected Date --></th>
            <th>{{ $t('msg.CMBA380.043') }}<!-- Approved Date --></th>
            <th>{{ $t('msg.ONEX010P040.005') }}<!-- Kind --></th>
          </tr>
        </thead>
        <tbody v-if="certificateList.length > 0">
          <template>
            <tr v-for="certificate in certificateList" :key="certificate.crtfNo"
                @click.prevent="modifyCertificate(certificate.crtfNo)"
                class="crtfNo"
            >
              <td :style="{ 'font-weight': certificate.crtfNo === params.crtfNo ? 'bold' : '' }">{{ certificate.crtfNo }}</td>
              <td :style="{ 'font-weight': certificate.crtfNo === params.crtfNo ? 'bold' : '' }">{{ certificate.apvStsNm }}</td>
              <td :style="{ 'font-weight': certificate.crtfNo === params.crtfNo ? 'bold' : '' }">{{ certificate.reqTm }}</td>
              <td :style="{ 'font-weight': certificate.crtfNo === params.crtfNo ? 'bold' : '' }">{{ certificate.updTm }}</td>
              <td :style="{ 'font-weight': certificate.crtfNo === params.crtfNo ? 'bold' : '' }">{{ certificate.apvTm }}</td>
              <td :style="{ 'font-weight': certificate.crtfNo === params.crtfNo ? 'bold' : '' }">{{ certificate.kindNm }}</td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">No data.</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="content_box mt10">
      <!-- content_box -->
      <h2 class="content_title">{{ $t('msg.ONEX050T010.007') }}</h2> <!-- Certificate -->
      <table class="tbl_row">
        <colgroup>
          <col width="130"><col><col width="130"><col>
        </colgroup>
        <tbody v-if="certificateDetail">
          <tr>
            <th>{{ $t('msg.ONEX050T010.008') }}</th> <!-- Title. -->
            <td colspan="3">
              <input type="text" id="title" name="title" v-model="contentsDetail.crtfTite">
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.ONEX050T010.009') }}</th> <!-- To. -->
            <td>{{ certificateDetail.toNm }}</td>
            <th>{{ $t('msg.ONEX050T010.010') }}</th> <!-- Date. -->
            <td>{{ certificateDetail.toDat }}</td>
          </tr>
          <tr>
            <th>{{ $t('msg.ONEX050T010.011') }}</th> <!-- ATTN. -->
            <td></td>
            <th>{{ $t('msg.ONEX050T010.003') }}</th> <!-- Ref. No. -->
            <td>{{ textCrtfNo }}</td>
          </tr>
          <tr>
            <th>{{ $t('msg.ONEX050T010.001') }}</th> <!-- B/L No. -->
            <td>{{ certificateDetail.blNo }}</td>
            <th>{{ $t('msg.ONEX050T010.012') }}</th> <!-- On Board Date. -->
            <td>{{ certificateDetail.obrdDt }}</td>
          </tr>
          <tr>
            <th>{{ $t('msg.ONEX050T010.013') }}</th> <!-- Vessel/Voyage. -->
            <td>
              {{ certificateDetail.vslNm }} {{ certificateDetail.voyNo }}
              <span class="float_right"><a class="button sm" href="#" @click.prevent="fnVesselInfoPop">{{ $t('msg.ONEX050T010.014') }}</a></span> <!-- Vessel Info -->
            </td>
            <th></th>
            <td></td>
          </tr>
          <tr>
            <th>{{ $t('msg.ONEX050T010.015') }}</th> <!-- POL. -->
            <td>{{ certificateDetail.polPortNm }}</td>
            <th>{{ $t('msg.ONEX050T010.016') }}</th> <!-- POD. -->
            <td>{{ certificateDetail.podPortNm }}</td>
          </tr>
        </tbody>
      </table>

      <div class="btn_area mt20" style="margin-right:210px;">
        <div class="align_right">
          <a class="button sm" @click.prevent="fnPreview">{{ $t('msg.ONEX050T010.017') }}</a> <!-- 미리보기 -->
          <a class="button sm" @click.prevent="fnGuidePop">{{ $t('msg.ONEX050T010.031') }}</a> <!-- Certificate 입력 안내 -->
          <a class="button sm" @click.prevent="fnEasyContentsPop">{{ $t('msg.ONEX050T010.018') }}</a> <!-- 쉬운 Certificate 입력 -->
        </div>
      </div>
      <div style="margin-left:200px;">
        <textarea
          name=""
          id=""
          v-model="params.crtfCont"
          @keyup="crtfContChange($event)"
          style="height:400px;"
          class="txt_desc1"
        ></textarea>
      </div>

      <h2 class="content_title">{{ $t('msg.ONEX050T010.019') }}</h2> <!-- Request -->
      <table class="tbl_col">
        <colgroup>
          <col style="width:50%">
          <col style="width:50%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.ONEX050T010.019') }}</th> <!-- Request -->
            <th>{{ $t('msg.ONEX050T010.020') }}</th> <!-- Answer -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <textarea name="" id="" v-model="params.rmk"></textarea>
            </td>
            <td>
              <textarea name="" id="" v-model="contentsDetail.rspsRmk" readonly></textarea>
            </td>
          </tr>
        </tbody>
      </table>

      <h2 class="content_title">{{ $t('msg.ONEX050T010.021') }}</h2> <!-- Certi 자동 알림 서비스 신청 -->
      <table class="tbl_row">
        <colgroup>
          <col width="150"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.ONEX050T010.022') }}</th> <!-- 자동 알림 서비스신청 -->
            <!--<td>
              <select name="" id="">
                <option value="">선택</option>
              </select>
            </td> -->
            <td>
              <a class="button sm" href="#" @click.prevent="fnMoveUrl">{{ $t('msg.ONEX050T010.023') }}</a> <!-- [My Information] – 자동알림서비스 신청↗ -->
              <span class="ml10">{{ $t('msg.ONEX050T010.024') }}</span> <!-- Certi 발행 결과를 원하시는 매체를 선택하여 받아 보실 수 있습니다. -->
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->

    <div class="flex_box mt10">
      <a class="button lg" href="#" @click.prevent="fnGoList">{{ $t('msg.ONEX050T010.025') }}</a> <!-- 목록 -->
      <template v-if="certificateDetail.crtfNo && params.initFlag == 'Y'">
        <a class="button blue lg mr3 ml_auto" href="#" @click.prevent="fnReload">NEW</a> <!-- 새로고침 -->
        <a class="button blue lg mr3" href="#" @click.prevent="fnSave">{{ $t('msg.ONEX050T010.026') }}</a> <!-- 저장 -->
        <a class="button blue lg" href="#" @click.prevent="fnDelete">{{ $t('msg.ONEX060P012.075') }}</a> <!-- 삭제 -->
      </template>
      <template v-else>
        <a class="button blue lg mr3 ml_auto" href="#" @click.prevent="fnSave">{{ $t('msg.ONEX050T010.026') }}</a> <!-- 저장 -->
      </template>
    </div>

    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePopup"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import blCertificate from '@/api/rest/trans/blCertificate'

let certificateText = ''

export default {
  name: 'BlCertificate',
  components: {
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'easy-certificate-pop': () => import('@/pages/trans/popup/EasyCertificatePop'),
    'certificate-guide-pop': () => import('@/pages/trans/popup/CertificateGuidePop'),
    'vessel-schedule-info-pop': () => import('@/pages/schedule/popup/VesselScheduleInfoPop'),
    'bl-call-pop': () => import('@/pages/trans/popup/BLCallPop'),
    'certificate-pop': () => import('@/pages/pdf/popup/CertificatePop')
  },
  props: {
    detailBkgNo: {
      type: String,
      default: ''
    },
    blNo: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      originParams: {},
      inputCrtfNo: '',
      certificateList: [],
      bookingDetail: {},
      certificateDetail: {},
      contentsDetail: {},
      crmManagerList: [],
      templateList: [],
      textCrtfNo: '',
      params: {
        initFlag: 'N',
        blNo: this.blNo,
        bkgNo: this.detailBkgNo,
        crtfNo: '',
        crtfCont: '',
        crtfTite: '',
        rmk: '',
        talkPicNm: '',
        talkMbpNo: '',
        talkPicNo: '',
        reqCont: '',
        salAutYn: ''
      },
      popParams: {},
      selectFunc: null,
      customComponent: null,
      lang: ''
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    if (this.auth !== undefined) {
      this.lang = this.auth.serviceLang
    }
    this._ = require('lodash')
    this.init()
  },
  methods: {
    async init () {
      const result = await blCertificate.getBlCertificate(this.params)

      //[현재 B/L No의 Certificate No와 Status Code List(화면 우측 상단 표시)]
      this.certificateList = result.data.certificateList

      //Certificate List 상태 정의
      if (this.certificateList.length > 0) {
        this.certificateList.map((item) => {
          if (item.apvStsCd === '01' || item.apvStsCd === '02' || item.apvStsCd === '05' || item.apvStsCd === '07') {
            item.apvStsNm = 'Waiting'
          } else if (item.apvStsCd === '03') {
            item.apvStsNm = 'Accept'
          } else if (item.apvStsCd === '04') {
            item.apvStsNm = 'Reject'
          } else if (item.apvStsCd === '07') {
            item.apvStsNm = 'Arranged'
          }
        })
        this.params.initFlag = 'Y'
      }

      //[화면 상단 정보]
      this.bookingDetail = result.data.bookingDetail
      this.certificateDetail = result.data.certificateDetail

      //[Certificate Main 정보]
      if (result.data.contentsDetail) {
        this.contentsDetail = result.data.contentsDetail
        if (this.contentsDetail) {
          if (!this.contentsDetail.crtfCont) {
            this.contentsDetail.crtfCont = ''
          }
          if (!this.contentsDetail.rmk) {
            this.contentsDetail.rmk = ''
          }
        }
      }
      //[현재 안 쓰는 정보(추후삭제)]
      this.crmManagerList.push(...result.data.crmManagerList)
      this.templateList.push(...result.data.templateList)

      //바인딩
      if (this.params.initFlag === 'Y') {
        this.params.crtfNo = this.contentsDetail.crtfNo
        this.textCrtfNo = this.certificateDetail.crtfNo
      } else {
        this.textCrtfNo = ''
      }

      this.params.crtfCont = this.contentsDetail.crtfCont
      this.params.rmk = this.contentsDetail.rmk
      certificateText = this.params.crtfCont

      let deepCopyParams = { ...this.params, inputCrtfNo: this.inputCrtfNo }
      this.originParams = this._.cloneDeep(deepCopyParams)
    },
    //쉬운 Certificate 입력 팝업 내용 추가
    selectSetting (obj) {
      let addCertificateText = (certificateText ? '\n' : '') + obj
      certificateText = addCertificateText
      this.params.crtfCont += certificateText
    },
    //쉬운 Certificate 입력 팝업 활성화
    fnEasyContentsPop () {
      this.popParams = {
        blNo: this.params.blNo,
        bkgNo: this.params.bkgNo
      }
      this.selectFunc = this.selectSetting

      this.openPopup('easy-certificate-pop')
    },
    //Certificate 입력 안내 팝업 활성화
    fnGuidePop () {
      this.openPopup('certificate-guide-pop')
    },
    async fnReload () {
      this.originParams = {}
      this.inputCrtfNo = ''
      this.bookingDetail = {}
      this.certificateDetail = {}
      await this.init()
      this.params = {
        initFlag: 'N',
        blNo: this.blNo,
        bkgNo: this.detailBkgNo,
        crtfNo: '',
        crtfCont: '\r\n *BASED ON REQUEST FOR ISSUING LINER CERTIFICATE ASKED BY SHIPPER,\r\n IT DESCRIBES AS FOLLOWS :\n',
        crtfTite: '',
        rmk: '',
        talkPicNm: '',
        talkMbpNo: '',
        talkPicNo: '',
        reqCont: '',
        salAutYn: ''
      }
      this.contentsDetail = {}
      // this.certificateList = []
      this.crmManagerList = []
      this.templateList = []
      this.textCrtfNo = ''
      this.popParams = {}
      this.selectFunc = null
      this.customComponent = null

      // 초기화
      certificateText = ''
    },
    //팝업 OPEN
    openPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.blCertificate .popup_dim')
    },
    //팝업 CLOSE
    closePopup () {
      this.customComponent = null
      this.selectFunc = null
      //certificateText = ''
      this.$ekmtcCommon.layerClose('.blCertificate .popup_dim')
    },
    //Certificate 저장
    async fnSave () {
      //Certification을 입력해주세요.
      if (!this.params.crtfCont) {
        this.$ekmtcCommon.alertDefault('Certification ' + this.$t('tem.CMAT002'))
        return
      }
      //Certification은 4000자를 넘을 수 업습니다.
      if (this.params.crtfCont.length > 3999) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050T010.027') + '(' + this.$t('msg.ONEX050T010.028') + ': ' + this.params.crtfCont.length + ' )')
        return
      }
      //Request는 2000자를 넘을 수 없습니다.
      if (this.params.rmk.length > 1999) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050T010.029') + '(' + this.$t('msg.ONEX050T010.028') + ': ' + this.params.rmk.length + ' )')
        return
      }

      //한글이 들어있는지 체크
      let info = this.params.crtfCont
      for (var i = 0; i < info.length; i++) {
        if ((info.charCodeAt(i) > 12592 && info.charCodeAt(i) < 12644) || (info.charCodeAt(i) > 44031 && info.charCodeAt(i) < 55204)) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050T010.030'))
          return
        }
      }

      this.params.crtfNo = this.contentsDetail.crtfNo
      this.params.crtfTite = this.contentsDetail.crtfTite
      this.params.talkPicNm = this.contentsDetail.talkPicNm
      this.params.salAutYn = this.contentsDetail.salAutYn

      //INSERT OR UPDATE
      if (this.params.initFlag === 'N') {
        const result = await blCertificate.insertCerti(this.params)
        if (result.headers.respcode === 'C0000') {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.012')) //요청이 접수되었습니다.
          this.init()
        } else {
          this.$ekmtcCommon.alertDefault('저장에 실패했습니다.') //저장에 실패했습니다.
        }
      } else {
        const certificate = this.certificateList.filter(certi => certi.crtfNo === this.params.crtfNo)
        const apvStsNm = certificate.length > 0 ? certificate[0].apvStsNm : null

        // if (this.certificateList[len].apvStsNm === 'Waiting') {
        if (apvStsNm === 'Waiting') {
          this.$ekmtcCommon.alertDefault(this.$t('js.certificate.001')) // SAY 란 ZERO(0) 으로 기재 불가합니다.\n해당 B/L에 선적하는 컨테이너 대수로 기재 바랍니다.\n예시) 1 + Tab -> ONE(1) CONTAINER ONLY.로 자동 기재됩니다
        } else if (apvStsNm === 'Accept' && this.$store.state.auth.loginInfo.userCtrCd === 'CN' && this.certificateDetail.checkYn === 'Y') {
          this.$ekmtcCommon.alertDefault("Can't be Updated. Already approved.")
        } else {
          if (this.contentsDetail.crtfCont === this.params.crtfCont) {
            this.$ekmtcCommon.alertDefault(this.$t('js.certificate.002')) // 정정 내역이 존재하지 않습니다.
          } else {
            const result = await blCertificate.updateCerti(this.params)
            if (result.headers.respcode === 'C0000') {
              this.$ekmtcCommon.alertDefault(this.$t('art.CMATK188')) //재승인이 필요합니다.
              this.init()
            } else {
              this.$ekmtcCommon.alertDefault('저장에 실패했습니다.') //저장에 실패했습니다.
            }
          }
        }
      }
    },
    //선박 정보 팝업 활성화
    fnVesselInfoPop () {
      this.selectFunc = ''
      this.popParams = {
          vslCd: this.certificateDetail.vslCd,
          voyNo: this.certificateDetail.voyNo,
          polPortCd: this.certificateDetail.polPortCd,
          podPortCd: this.certificateDetail.podPortCd,
          bound: 'O'
      }
      this.openPopup('vessel-schedule-info-pop')
    },
    //템플릿 적용하기
    async fnApplyCertiTemplate () {
      if (!this.inputCrtfNo) {
        this.$ekmtcCommon.alertDefault('Ref. No. ' + this.$t('tem.CMAT002'))
        return
      }
      const result = await blCertificate.applyCertiTemplate(this.inputCrtfNo)
      if (result.headers.respcode === 'C0000') {
        this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT015')) //적용되었습니다.
        certificateText = result.data
        this.params.crtfCont = result.data
      } else {
        this.$ekmtcCommon.alertDefault('Ref No.' + this.$t('tem.CMAT005')) //Ref No.를 확인하세요.
      }
    },
    //불러오기 팝업 클릭 후 Certificate 가져오기
    async findCertificate (obj) {
      const template = await blCertificate.applyCertiTemplate(obj.crtfNo)
      this.params.crtfCont = template.data
      certificateText = template.data
      this.inputCrtfNo = obj.crtfNo
    },
    //불러오기 팝업
    fnCallPop () {
      this.selectFunc = this.findCertificate
      this.popParams = {
        searchFlag: 'CRTF'
      }
      this.openPopup('bl-call-pop')
    },
    //불러오기 엔터 이벤트
    templateEnter (e) {
      if (e.keyCode === 13) {
        this.fnCallPop()
      }
    },
    //미리보기
    fnPreview () {
      const crtfNo = this.certificateDetail.crtfNo
      const crtfTite = this.contentsDetail.crtfTite

      if (!crtfNo) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.245')) //저장후 출력 가능합니다.
      } else {
        this.popParams = {
          items: [
            {
              crtfNo: crtfNo,
              crtfTite
            }
          ]
        }
        this.selectFunc = ''
        this.openPopup('certificate-pop')
      }
    },
    crtfContChange (e) {
      certificateText = this.params.crtfCont
    },
    //Certificate 삭제
    async fnDelete () {
      console.log('@@@@ enter fnDelete')
      const apvStsCd = this.certificateDetail.apvStsCd

      if (apvStsCd === '03' || apvStsCd === '04' || apvStsCd === '06') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL310.017')) //승인완료이거나 요청전이므로 삭제할 수 없습니다.
        return
      }

      if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('tem.CMAT023'), useConfirmBtn: true })) {
        return
      }

      const result = await blCertificate.deleteCerti(this.certificateDetail.crtfNo)

      if (result.headers.respcode === 'C0000') {
        this.$ekmtcCommon.alertDefault(this.$t('삭제되었습니다.'))
        // const els = document.getElementsByClassName('certiNo')
        // els.forEach((el) => {
        //   el.style.fontWeight = ''
        // })
        this.params.initFlag = 'N'
        this.textCrtfNo = ''
        this.init()
      }
    },
    //목록으로 이동
    fnGoList () {
      this.$router.push({ name: 'progress' }).catch(() => {})
    },
    //기존 Certificate 찾기
    modifyCertificate (crtfNo) {
      if (this.params.crtfNo === crtfNo) return
      //Certificate No List 하이라이트
      // const els = document.getElementsByClassName('certiNo')
      // Array.prototype.forEach.call(els, function (el) {
      //   el.style.fontWeight = ''
      // })
      // document.getElementById(crtfNo).style.fontWeight = 'bold'

      //넘길 파라미터(Certificate No, flag) 셋팅
      this.params.crtfNo = crtfNo
      // this.params.initFlag = 'Y'

      this.init()
    },
    //자동알림서비스 신청 페이지 이동
    fnMoveUrl () {
      window.open('../index.html#/my-info/setting/profile', 'win')
    },
    compareCheck () {
      let isChange = false
      let modifiedParams = { ...this.params, inputCrtfNo: this.inputCrtfNo }
      const res = this.$ekmtcCommon.compareObject(modifiedParams, this.originParams)

      if (res.length > 0) {
        isChange = true
      }

      return isChange
    }
  }
}
</script>

<style scoped>
  .tbl_search th {vertical-align: top;}

  .txt_desc1 {
    font-family: 'Courier New';
    width: 800px;
  }

  .crtfNo{
    cursor: pointer;
  }
</style>
