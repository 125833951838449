var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "blCertificate capture_area" },
    [
      _c("div", { staticClass: "content_box beforetab" }, [
        _c("table", { staticClass: "tbl_search" }, [
          _vm._m(0),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.001")))]),
              _vm._v(" "),
              _c("td", [_vm._v(" " + _vm._s(_vm.blNo) + " ")]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.002")))]),
              _vm._v(" "),
              _c("td", [_vm._v(" " + _vm._s(_vm.params.bkgNo) + " ")]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("table", { staticClass: "tbl_search" }, [
          _c("colgroup", [
            _vm.lang === "JPN"
              ? _c("col", { attrs: { width: "160px" } })
              : _c("col", { attrs: { width: "120px" } }),
            _c("col"),
          ]),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.393")))]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.inputCrtfNo,
                      expression: "inputCrtfNo",
                    },
                  ],
                  staticClass: "wid200",
                  attrs: { type: "text", id: "callPop", name: "callPop" },
                  domProps: { value: _vm.inputCrtfNo },
                  on: {
                    keypress: _vm.templateEnter,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.inputCrtfNo = $event.target.value
                    },
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "tbl_icon search",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnCallPop.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.005")))]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "button blue sh ml15",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnApplyCertiTemplate.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.006")))]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("table", { staticClass: "tbl_col" }, [
          _vm._m(1),
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL100_P1.016")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONGO080G010.011")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA380.005")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA380.042")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA380.043")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P040.005")))]),
            ]),
          ]),
          _vm.certificateList.length > 0
            ? _c(
                "tbody",
                [
                  _vm._l(_vm.certificateList, function (certificate) {
                    return _c(
                      "tr",
                      {
                        key: certificate.crtfNo,
                        staticClass: "crtfNo",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.modifyCertificate(certificate.crtfNo)
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          {
                            style: {
                              "font-weight":
                                certificate.crtfNo === _vm.params.crtfNo
                                  ? "bold"
                                  : "",
                            },
                          },
                          [_vm._v(_vm._s(certificate.crtfNo))]
                        ),
                        _c(
                          "td",
                          {
                            style: {
                              "font-weight":
                                certificate.crtfNo === _vm.params.crtfNo
                                  ? "bold"
                                  : "",
                            },
                          },
                          [_vm._v(_vm._s(certificate.apvStsNm))]
                        ),
                        _c(
                          "td",
                          {
                            style: {
                              "font-weight":
                                certificate.crtfNo === _vm.params.crtfNo
                                  ? "bold"
                                  : "",
                            },
                          },
                          [_vm._v(_vm._s(certificate.reqTm))]
                        ),
                        _c(
                          "td",
                          {
                            style: {
                              "font-weight":
                                certificate.crtfNo === _vm.params.crtfNo
                                  ? "bold"
                                  : "",
                            },
                          },
                          [_vm._v(_vm._s(certificate.updTm))]
                        ),
                        _c(
                          "td",
                          {
                            style: {
                              "font-weight":
                                certificate.crtfNo === _vm.params.crtfNo
                                  ? "bold"
                                  : "",
                            },
                          },
                          [_vm._v(_vm._s(certificate.apvTm))]
                        ),
                        _c(
                          "td",
                          {
                            style: {
                              "font-weight":
                                certificate.crtfNo === _vm.params.crtfNo
                                  ? "bold"
                                  : "",
                            },
                          },
                          [_vm._v(_vm._s(certificate.kindNm))]
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _c("tbody", [_vm._m(2)]),
        ]),
      ]),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("h2", { staticClass: "content_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX050T010.007"))),
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "tbl_row" }, [
          _vm._m(3),
          _vm.certificateDetail
            ? _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.008")))]),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.contentsDetail.crtfTite,
                          expression: "contentsDetail.crtfTite",
                        },
                      ],
                      attrs: { type: "text", id: "title", name: "title" },
                      domProps: { value: _vm.contentsDetail.crtfTite },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.contentsDetail,
                            "crtfTite",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.009")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.certificateDetail.toNm))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.010")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.certificateDetail.toDat))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.011")))]),
                  _vm._v(" "),
                  _c("td"),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.003")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.textCrtfNo))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.001")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.certificateDetail.blNo))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.012")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.certificateDetail.obrdDt))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.013")))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.certificateDetail.vslNm) +
                        " " +
                        _vm._s(_vm.certificateDetail.voyNo) +
                        " "
                    ),
                    _c("span", { staticClass: "float_right" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button sm",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnVesselInfoPop.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.014")))]
                      ),
                    ]),
                  ]),
                  _c("th"),
                  _c("td"),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.015")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.certificateDetail.polPortNm))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.016")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.certificateDetail.podPortNm))]),
                ]),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            staticClass: "btn_area mt20",
            staticStyle: { "margin-right": "210px" },
          },
          [
            _c("div", { staticClass: "align_right" }, [
              _c(
                "a",
                {
                  staticClass: "button sm",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.fnPreview.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.017")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button sm",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.fnGuidePop.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.031")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button sm",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.fnEasyContentsPop.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.018")))]
              ),
            ]),
          ]
        ),
        _c("div", { staticStyle: { "margin-left": "200px" } }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.params.crtfCont,
                expression: "params.crtfCont",
              },
            ],
            staticClass: "txt_desc1",
            staticStyle: { height: "400px" },
            attrs: { name: "", id: "" },
            domProps: { value: _vm.params.crtfCont },
            on: {
              keyup: function ($event) {
                return _vm.crtfContChange($event)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.params, "crtfCont", $event.target.value)
              },
            },
          }),
        ]),
        _c("h2", { staticClass: "content_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX050T010.019"))),
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "tbl_col" }, [
          _vm._m(4),
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.019")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.020")))]),
            ]),
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.params.rmk,
                      expression: "params.rmk",
                    },
                  ],
                  attrs: { name: "", id: "" },
                  domProps: { value: _vm.params.rmk },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.params, "rmk", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("td", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.contentsDetail.rspsRmk,
                      expression: "contentsDetail.rspsRmk",
                    },
                  ],
                  attrs: { name: "", id: "", readonly: "" },
                  domProps: { value: _vm.contentsDetail.rspsRmk },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.contentsDetail,
                        "rspsRmk",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
        _c("h2", { staticClass: "content_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX050T010.021"))),
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "tbl_row" }, [
          _vm._m(5),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.022")))]),
              _vm._v(" "),
              _c("td", [
                _c(
                  "a",
                  {
                    staticClass: "button sm",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnMoveUrl.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.023")))]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "ml10" }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX050T010.024"))),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "flex_box mt10" },
        [
          _c(
            "a",
            {
              staticClass: "button lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.fnGoList.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.025")))]
          ),
          _vm._v(" "),
          _vm.certificateDetail.crtfNo && _vm.params.initFlag == "Y"
            ? [
                _c(
                  "a",
                  {
                    staticClass: "button blue lg mr3 ml_auto",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnReload.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("NEW")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "button blue lg mr3",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnSave.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.026")))]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "button blue lg",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnDelete.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX060P012.075")))]
                ),
              ]
            : [
                _c(
                  "a",
                  {
                    staticClass: "button blue lg mr3 ml_auto",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.fnSave.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX050T010.026")))]
                ),
              ],
        ],
        2
      ),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popParams },
                on: { close: _vm.closePopup, selectFunc: _vm.selectFunc },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "70px" } }),
      _c("col"),
      _c("col", { attrs: { width: "100px" } }),
      _c("col"),
      _c("col", { attrs: { width: "70px" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("No data.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "50%" } }),
      _c("col", { staticStyle: { width: "50%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "150" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }